
import { defineComponent } from 'vue';
import HomeHeader from '@/components/HomeHeader.vue';
import TypeEffect from '@/components/TypeEffect.vue';
import Axios from 'axios'

const msgBuffer: any = [];
export default defineComponent({
  name: 'Home',
  components: { HomeHeader, TypeEffect },
  data: () => ({ message: '', id: '', token: '', dialogs: ['Hello!'], runing: true, ask:([] as Array<string>) }),

  // async unmounted() {
  //   await Axios.request({
  //       method: 'post',
  //       data: JSON.stringify({ type: "endOfConversation", from: { id: "user1" }}),
  //       url: `https://directline.botframework.com/v3/directline/conversations/${this.id}/activities`,
  //       headers: { 
  //         'Authorization': 'Bearer ' + this.token,
  //         'Content-Type': 'application/json'
  //       }
  //     })
  // },

  async mounted() {
    let response = await Axios.request({
      method: 'post',
      url: 'https://directline.botframework.com/v3/directline/tokens/generate',
      headers: { 
        'Authorization': 'Bearer szsqvMLK6nM.Rfd3D2RO8zp0z2DraR_ySII83XjxEQX4KMUIyY0BF9Q'
      }
    })

    this.id = response.data.conversationId
    const startToken = response.data.token

    response = await Axios.request({
      method: 'post',
      url: 'https://directline.botframework.com/v3/directline/conversations',
      headers: { 
        'Authorization': 'Bearer ' + startToken
      }
    })

    let final=false
    let redirect: any = null
    this.token = response.data.token
    const socket = new WebSocket(response.data.streamUrl);
    (window as any).socket = socket;

    socket.onmessage = (evt: any) => 
    { 
      if(!evt.data) return;
      const message = JSON.parse(evt.data);
      if(['0', '1'].includes(message.watermark)) return;
      const activities: Array<any> = [].concat(message.activities)

      for (const item of activities) {
        if(item.from.id == "cblx-bf2030-bot") {
          const attachments = item.attachments || [];
          
          if(final) {
            socket.close()
            const s = (this as any).$store;
            s.commit('setFinalMessage', item.text)
            return;
          }
          
          if(redirect != null) {
            redirect.text = item.text.split('your gift')[0]+'your gift'
            this.$router.push({ name: 'cards', params: redirect })
            final=true
          }

          if(attachments.length > 0) {
            redirect = {
              id: this.id,
              token: this.token,
              items: JSON.stringify(attachments.map((a: any) => a.content))
            }
          }
          else if(item.text) {
            this.runing = true
            msgBuffer.push(item.text)
          }
        }
      }
    };
  },

  watch: {
    runing(value) {
      if(value) {
        this.dialogs.push(msgBuffer.shift())
      }
    }
  },

  methods: {
    canWrite(i: number) {
      if(i === 0) {
        this.dialogs.push(`I am Gift, and I'm here to facilitate donations between people who have something to give and non-profit organizations in need of that very same thing.`)
        return
      }

      if(i === 1) {
        this.dialogs.push('Do you want to donate/volunteer or are you a NPO?')
        return
      }

      this.runing = false
      if(msgBuffer.length) {
        this.dialogs.push(msgBuffer.shift())
        return
      }

      this.$nextTick(() => {
        const input = this.$refs.txtMessage as any;
        input.focus();
      })
    },

    async send() {
      const payload = JSON.stringify({
        locale: "en-EN",
        type: "message",
        from: {
          id: "user1"
        },
        text: this.message
      })

      this.ask.push(this.message)
      this.message = '';
      
      await Axios.request({
        method: 'post',
        data: payload,
        url: `https://directline.botframework.com/v3/directline/conversations/${this.id}/activities`,
        headers: { 
          'Authorization': 'Bearer ' + this.token,
          'Content-Type': 'application/json'
        }
      })
    }
  },
});
