import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-78004a8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "user-messages" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_type_effect = _resolveComponent("type-effect")!
  const _component_HomeHeader = _resolveComponent("HomeHeader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HomeHeader, null, {
      messages: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dialogs, (d, i) => {
          return (_openBlock(), _createBlock(_component_type_effect, {
            key: i,
            strings: [d],
            first: i===0,
            current: i,
            last: i == 2,
            total: _ctx.dialogs.length,
            onEnd: ($event: any) => (_ctx.canWrite(i))
          }, null, 8, ["strings", "first", "current", "last", "total", "onEnd"]))
        }), 128))
      ]),
      _: 1
    }),
    _createElementVNode("div", {
      class: _normalizeClass(["glow", {'active': _ctx.runing}])
    }, null, 2),
    _withDirectives(_createElementVNode("input", {
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.message) = $event)),
      disabled: _ctx.runing,
      onChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.send())),
      type: "text",
      ref: "txtMessage",
      class: "user-input",
      placeholder: "Write your message here"
    }, null, 40, _hoisted_1), [
      [_vModelText, _ctx.message]
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ask.slice().reverse(), (item, i) => {
        return (_openBlock(), _createElementBlock("p", {
          key: 'ask'+i
        }, _toDisplayString(item), 1))
      }), 128))
    ])
  ], 64))
}