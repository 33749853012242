<template>
    <div class="container" :style="{'transform': translateY, 'opacity': opacity}">
        <div class="line">
            <h1 v-if="first" class="typed-title" v-text="typeValue"></h1>
            <span v-else class="typed-text" :class="{'last': last}" v-text="typeValue"></span>
            <span class="cursor" v-if="showCursor" :class="{'typing': typeStatus, 'first': first}">&nbsp;</span>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
  export default defineComponent({
    name: 'TypeEffect',
    props: ['strings', 'first', 'last', 'total', 'current'],
    emits: ['end'],

    computed: {
      opacity(){
        let val = this.total - this.current
        if(val <= 3) return '1'
        if(val > 3 && val < 5) return '0.5'
        else return '0'
      },

      translateY() {
        let val = 0
        if(this.total > 3) val = (this.total -3) * 70
        return `translateY(-${val}px)`
      }
    },

    data: () => {
      return {
        once: true,
        typeValue: '',
        typeStatus: false,
        showCursor: true,
        typingSpeed: 70,
        erasingSpeed: 50,
        newTextDelay: 100,
        typeArrayIndex: 0,
        charIndex: 0
      }
    },

    methods: {
      typeText() {
        if(this.charIndex < this.strings[this.typeArrayIndex].length) {
          if(!this.typeStatus)
            this.typeStatus = true;

          this.typeValue += this.strings[this.typeArrayIndex].charAt(this.charIndex);
          this.charIndex += 1;
          setTimeout(this.typeText, this.typingSpeed);
        }
        else {
          this.typeStatus = false;
          if(this.once) {
              this.showCursor = false
              this.$emit('end')
              return;
          }
          setTimeout(this.eraseText, this.newTextDelay);
        }
      },

      eraseText() {
        if(this.charIndex > 0) {
          if(!this.typeStatus)
            this.typeStatus = true;
          this.typeValue = this.strings[this.typeArrayIndex].substring(0, this.charIndex - 1);
          this.charIndex -= 1;
          setTimeout(this.eraseText, this.erasingSpeed);
        }
        else {
          this.typeStatus = false;
          this.typeArrayIndex += 1;
          if(this.typeArrayIndex >= this.strings.length)
            this.typeArrayIndex = 0;
          setTimeout(this.typeText, this.typingSpeed);
        }
      }
    },

    created() {
      setTimeout(this.typeText, this.newTextDelay + 200);
    }
})
</script>

<style scoped lang="scss">
.container {
    height: 70px;
    padding: 0 6px;
    width: calc(100% - 12px);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    transition: ease-out 500ms transform, linear 300ms opacity;
}

.typed-title {
  font: normal normal 900 50px/68px Segoe UI;
  letter-spacing: -1px;
  color: #FFFFFF;
  display: inline-block;
  margin-top: 0;
}

.typed-text {
  font: normal normal normal 18px/26px Segoe UI;
  letter-spacing: -0.36px;
  color: #FFFFFF;
  display: inline;
}

@media screen and(max-width: 600px) {
  .typed-text {
    font-size: 16px;
    &.last {
      margin-top: 24px;
      display: inline-block;
    }
  }
}

.line {
    font-weight: normal;
    width: 100%;
    text-align: center;
    
    span.cursor {
        display: inline-block;
        margin-left: 3px;
        width: 2px;
        background-color: #fff;
        animation: cursorBlink 1s infinite;
        &.first {
          line-height: 68px;
          height: 50px;
        }
    }
    
    span.cursor.typing {
        animation: none;
    }
}

@keyframes cursorBlink {
    49% { background-color: #fff; }
    50% { background-color: transparent; }
    99% { background-color: transparent; }
}
</style>