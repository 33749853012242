import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    name: 'cards',
    path: '/donate',
    component: () => import(/* webpackChunkName: "cards" */ '../views/Cards.vue')
  },
  {
    name: 'maps',
    path: '/places',
    component: () => import(/* webpackChunkName: "maps" */ '../views/Maps.vue')
  }
]


export default createRouter({
  history: createWebHashHistory(),
  routes
})
